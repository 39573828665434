<template>
  <div>

  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },

  mounted() {
    // 跳转到财务大屏页面
    // this.$router.push('/largeScreen');
    // 打开新的标签页
    window.open('/financelargeScreen');
  },

  methods:{

  },

}
</script>

<style lang="scss" scoped>

</style>
